import { AxiosPromise, AxiosResponse } from 'axios';
import {
  MapTradeObject,
  ObjectOfTrade,
  TradeObject,
  TradeObjectPreview,
} from '@/types/ObjectOfTrade';

import { Pageable, PaginationParams } from '@/types/Common';
import { downloadFileDynName } from '@/utils';
import axios from '../axios';

export interface ObjectOfTradeApi {
  findAll(params?: {filter?: string, status?: string, worker?: number}): AxiosPromise<ObjectOfTrade[]>
  findAllPreview(params?: { AddressOrInn?: string; status?: string; } & PaginationParams): AxiosPromise<Pageable<TradeObjectPreview>>
  getById(id: string | string[], duplicate: boolean): AxiosPromise<ObjectOfTrade>
  getInfoById(id: string | string[]): AxiosPromise<TradeObject>
  getByUgnsId(id: number, params: { worker?: number; address?: string; status?: string; } & PaginationParams): AxiosPromise<Pageable<TradeObjectPreview>>
  save(data: Record<string, any>): AxiosPromise<void>
  saveById(id: number, data: Record<string, any>): AxiosPromise<void>
  assignWorkerToObject(id: number, body: { tradeobjects: number[]}): AxiosPromise<void>
  loadfile(file: File): AxiosPromise<void>
  downloadTemplate(): Promise<void>
  assignWorker(tradeObjects: Record<string, unknown>[]): AxiosPromise<void>
  mapFindAll(): AxiosPromise<MapTradeObject[]>
  getKadBox(cooridnates: string): Promise<AxiosResponse>
  getExcelTradeObject(params?: Record<string, unknown>): Promise<void>
}
const getExcelTradeObject: ObjectOfTradeApi['getExcelTradeObject'] = (params = {}) => axios.get('/tradeobject/excel', { params, responseType: 'blob' }).then(downloadFileDynName);
const findAll: ObjectOfTradeApi['findAll'] = (params) => axios.get('/tradeobject/findall', { params });
const findAllPreview: ObjectOfTradeApi['findAllPreview'] = (params) => axios.get('/tradeobject/registry/gettradeobjects', { params });

const mapFindAll: ObjectOfTradeApi['mapFindAll'] = () => axios.get('/tradeobject/yandexmap/gettradeobjects');

const getById: ObjectOfTradeApi['getById'] = (id, duplicate) => axios.get(`/tradeobject/get/${id}`, { params: { duplicate } });
const getInfoById: ObjectOfTradeApi['getInfoById'] = (id) => axios.get(`/tradeobject/${id}/info`);
const getByUgnsId: ObjectOfTradeApi['getByUgnsId'] = (id, params) => axios.get(`/ugnstno/${id}/objects`, { params });

const save: ObjectOfTradeApi['save'] = (data) => axios.post('/tradeobject/save', data);
const saveById: ObjectOfTradeApi['saveById'] = (id, data) => axios.put(`/tradeobject/${id}`, data);

const assignWorker: ObjectOfTradeApi['assignWorker'] = (data) => axios.post('/tradeobject/add/workerobjects', data);
const assignWorkerToObject: ObjectOfTradeApi['assignWorkerToObject'] = (id, body) => axios.put(`/tradeobject/${id}/objectWorker`, body.tradeobjects);

const loadfile: ObjectOfTradeApi['loadfile'] = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/tradeobject/loadfile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getKadBox: ObjectOfTradeApi['getKadBox'] = async (coordinates) => axios.get('https://apiv2.taxcontrol-24.ru/tradeobject/yandexmap/kadbbox', { params: { bbox: coordinates } });
const downloadTemplate: ObjectOfTradeApi['downloadTemplate'] = async () => {
  const response = await axios.get('/template/getfile?template=TRADEOBJECTS', { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Шаблон загрузки торговых объектов.xlsx');
  document.body.appendChild(link);
  link.click();
};

const objectsOfTrade: ObjectOfTradeApi = {
  getExcelTradeObject,
  findAll,
  findAllPreview,
  getById,
  getInfoById,
  getByUgnsId,
  save,
  saveById,
  loadfile,
  downloadTemplate,
  assignWorker,
  assignWorkerToObject,
  mapFindAll,
  getKadBox,
};

export default objectsOfTrade;
