
import { computed, defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'Btn',
  props: {
    type: { required: false, type: String as PropType<'primary' | 'danger-outline' | 'danger'> },
    to: { required: false, type: String },
    fullWidth: { required: false, type: Boolean },
    size: { required: false, type: String as PropType<'large' | 'small'> },
  },
  setup(props) {
    const btnClass = computed(() => ({
      'btn--fullwidth': props.fullWidth,
      'btn--primary': props.type === 'primary',
      'btn--danger-outline': props.type === 'danger-outline',
      'btn--danger': props.type === 'danger',
      'btn--large': props.size === 'large',
      'btn--small': props.size === 'small',
    }));
    return { btnClass };
  },
});

