import { AxiosPromise } from 'axios';
import axios from '@/axios';
import { Pageable, PaginationParams } from '@/types/Common';
import {
  Market, MarketCreate, MarketTaxpayer, SubMarket, Passage,
} from '@/types/Markets';

interface PageablePassages {
  content: {
    id: number;
    passages: Passage[]
  };
  page: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
}

export interface MarketsApi {
  findAll(params?: { ugnsId?: number, web?: boolean }): AxiosPromise<Record<string, any>[]>
  findSubs(id: number, params?: PaginationParams): AxiosPromise<Pageable<SubMarket>>
  findPassages(id: number, params?: PaginationParams): AxiosPromise<PageablePassages>
  getById(id: number | string): AxiosPromise<Market>
  getTaxpayers(id: number, params?: Record<string, unknown>): AxiosPromise<Pageable<MarketTaxpayer>>
  updateById(id: string | string[], data: Record<string, unknown>): AxiosPromise<Record<string, unknown>>
  create(data: MarketCreate): AxiosPromise<Record<string, unknown>>
  createByFile(file: File): AxiosPromise<Record<string, unknown>>
  downloadTemplate(): Promise<void>
  updateSubMarket(data: SubMarket): Promise<void>
  updateMarket(data: Market): Promise<void>
  deleteMarket(id: number): Promise<void>
  updatePassage(data: Passage): AxiosPromise<Pageable<Passage>>
  deletePassage(id: number): Promise<void>
}

const findAll: MarketsApi['findAll'] = (params) => axios.get('/markets', { params });
const findSubs: MarketsApi['findSubs'] = (id, params) => axios.get(`/markets/${id}/subs`, { params });
const findPassages: MarketsApi['findPassages'] = (id, params) => axios.get(`/markets/${id}/passages`, { params });
const getById: MarketsApi['getById'] = (id) => axios.get(`/markets/${id}`);
const getTaxpayers: MarketsApi['getTaxpayers'] = (id, params) => axios.get(`/markets/${id}/taxpayers`, { params });
const updateById: MarketsApi['updateById'] = (id) => axios.put(`/markets/${id}`);
const create: MarketsApi['create'] = (data) => axios.post('/markets/loadfile', data);
const createByFile: MarketsApi['createByFile'] = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post('/markets/loadfile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
const updateSubMarket: MarketsApi['updateSubMarket'] = (data) => axios.put(`/markets/subs/${data.id}`, data);
const updateMarket: MarketsApi['updateMarket'] = (data) => axios.put(`/markets/${data.id}`, data);
const deleteMarket: MarketsApi['deleteMarket'] = (id) => axios.delete(`/markets/${id}`);
const updatePassage: MarketsApi['updatePassage'] = (data) => axios.put(`/markets/passages/${data.id}`, data);
const deletePassage: MarketsApi['deletePassage'] = (id) => axios.delete(`markets/passages/${id}`);

const downloadTemplate: MarketsApi['downloadTemplate'] = async () => {
  const response = await axios.get('/template/getfile?template=MARKET', { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'Шаблон загрузки торговых центров.xlsx');
  document.body.appendChild(link);
  link.click();
};

const markets: MarketsApi = {
  findAll,
  findSubs,
  findPassages,
  getById,
  getTaxpayers,
  updateById,
  create,
  createByFile,
  downloadTemplate,
  updateSubMarket,
  updateMarket,
  deleteMarket,
  updatePassage,
  deletePassage,
};

export default markets;
