import { Worker } from '@/types/Ugnstno';

export interface State {
  token: string,
  refreshKey: string,
  userInfo: Worker | null;
  isTno: boolean;
  isAdmin: boolean;
  isCA: boolean;
  isDisabled: boolean;
  isTno2: boolean;
}

export const state: State = {
  token: '',
  refreshKey: '',
  userInfo: null,
  get isTno() {
    return !!this.userInfo?.roles && ['TNO'].includes(this.userInfo.roles.join(','));
  },
  get isAdmin() {
    return !!this.userInfo?.roles && ['ADMIN'].includes(this.userInfo.roles.join(','));
  },
  get isCA() {
    return !!this.userInfo?.roles && ['CA'].includes(this.userInfo.roles.join(','));
  },
  get isDisabled() {
    return !this.isAdmin && !this.isTno && !this.isCA && !this.isTno2;
  },
  get isTno2() {
    return !!this.userInfo?.roles && ['TNO2'].includes(this.userInfo.roles.join(','));
  },
};
